/*
Joints Scripts File

This file should contain any js scripts you want to add to the site.
Instead of calling it in the header or throwing it inside wp_head()
this file will be called automatically in the footer so as not to
slow the page load.

*/


// as the page loads, call these scripts
jQuery(document).ready(function($) {
    socialToggle();
    // hompeage forms
    $('.widget_black_studio_tinymce').has('.pma-subscription-widget').attr('id', 'new-middleware-form');

    // Add star to required field placeholders
    $('.pma-subscription-widget').find("input[type=textarea].required, input[type=text].required, input[type=email].required").each(function(ev) {
        if( !$(this).val() ) { 
            placeholder = $(this).attr("placeholder");
            $(this).attr("placeholder", placeholder+"*");
        }
    });

    // Donation sidebar form focus, show privacy, hide on unfocus
    $('.home #pma-donation-shortcode').delegate(':input', 'focus', function() {
        $('#pma-donation-shortcode .email_optin').fadeIn();
    });

    // Enews sidebar form focus, show privacy, hide on unfocus
    $('.pma-subscription-shortcode').delegate(':input', 'focus', function() {
        $('.pma-subscription-shortcode .opt-ins').fadeIn();
    });


    $('.pinterest-button').click(function (e) {
      (function (url, title, w, h) {
        var left = (screen.width/2)-(w/2);
        var top = (screen.height/2)-(h/2);
        return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
      })($(this).attr('href'), 'Pinterest', 600, 280);
      sendSocialEvent('Pinterest', 'PinIt', window.location); 
      e.preventDefault();
    });

    $('#breadcrumbs a').click(function(){
        sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Mobile Breadcrumb Click');
    });
    $('.content-header .donate-button a').click(function(){
        sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Donate Header Button');
    });

    $('.main-content img.pinit').each(function() {
        var img_url = $(this).attr('src');
        var img_alt = $(this).attr('alt');
        var page_url = $(location).attr('href');
        var page_title = document.getElementsByTagName("title")[0].innerHTML;
        var pinit_link = '<div class="peta-pinit"><a href="http://www.pinterest.com/pin/create/button/?url='+page_url+'&media='+img_url+'&description='+page_title+'+'+img_alt+'" data-pin-do="buttonPin" data-pin-config="none" data-pin-color="red" data-pin-height="28"><img src="http://assets.pinterest.com/images/pidgets/pinit_fg_en_rect_red_28.png" /></a></div>';
        if ($(this).parent().is("a")) {
            $(this).parent().after(pinit_link);
        } else {
            $(this).after(pinit_link);
        }
    });

    /* 'Animals are not ours to' is in the menu but should not be a link */
    $("li#menu-item-111 a").contents().unwrap().wrap( "<span></span>" ); 

    /* Responsive Nav */
    $('.nav-slide').click(function(){
        $('body').toggleClass('active-nav');
        if( $('body').hasClass('active-search') ) {
            $('body').removeClass('active-search');
            sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Mobile Menu Close');
        } else {
            sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Mobile Menu Open');
        }
        return false;
    });

    $('.search-slide').click(function(){
        $('body').toggleClass('active-search');
        if( $('body').hasClass('active-nav') ) {
            $('body').removeClass('active-nav');
            sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Mobile Search Close');
        } else {
            sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Mobile Search Open');
        }
        return false;
    });
    
    $('.mobile-site-overlay').click (function (e) {
        if (e.target != $('#main-nav')) {
            if( $('body').hasClass('active-nav') ) {
                $('body').removeClass('active-nav');
                sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Mobile Menu Close');
            }
        }
        if (e.target != $('.search-wrap')) {
            if( $('body').hasClass('active-search') ) {
                $('body').removeClass('active-search');
                sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Mobile Search Close');
            }
        }
    });
    
    $('<div class="enews-middleware-placeholder"></div>').insertAfter('#new-middleware-form');
    $('<div class="enews-placeholder"></div>').insertAfter('#enews-sidebar');
    $('<div class="donation-placeholder"></div>').insertAfter('#donate-form-sidebar');

    enquire.register("screen and (max-width: 640px)", {
        setup : function() {

        },
        match : function() {
            $('h2.comments-header').click(function(){
                $(this).toggleClass('comments-open');
                $('ul.comments').slideToggle();
            });
        },
        unmatch : function() {
            $('ul.comments').show();
        }  
    });

    $('#donate-form-sidebar h5').click(function(){
        if ($(window).width() < 441) {
            if ( $(this).hasClass('open') ) {
                $(this).removeClass('open');
                $('#donate-form-sidebar form').slideUp();
            } else {
                $(this).addClass('open');
                $('#donate-form-sidebar form').slideDown();
            }
        }
        return false;
    });

    $('#enews-sidebar h3').click(function(){
        if ($(window).width() < 441) {
            if ( $(this).hasClass('open') ) {
                $(this).removeClass('open');
                $('#enews-sidebar .ea_widget_container').slideUp();
            } else {
                $(this).addClass('open');
                $('#enews-sidebar .ea_widget_container').slideDown();
            }
        }
        return false;
    });

    $('#new-middleware-form h3').click(function(){
        if ($(window).width() < 441) {
            if ( $(this).hasClass('open') ) {
                $(this).removeClass('open');
                $('#new-middleware-form .subscription-form').slideUp();
            } else {
                $(this).addClass('open');
                $('#new-middleware-form .subscription-form').slideDown();
            }
        }
        return false;
    });

    enquire.register("screen and (max-width: 440px)", {
        setup : function() {
        },
        match : function() {
            $('.home-donation-widget').insertAfter('#rotator-wrap');
            $('#enews-sidebar').insertAfter('#rotator-wrap');
            $('#enews-sidebar .ea_widget_container').hide();

            $('#new-middleware-form').insertAfter('#rotator-wrap');
            $('#new-middleware-form .subscription-form').hide();

            $('#donate-form-sidebar form').hide();
        },
        unmatch : function() {
            $('#donate-form-sidebar').prependTo('.donation-placeholder');
            $('#enews-sidebar').prependTo('.enews-placeholder');
            $('#enews-sidebar h3').removeClass('open');

            $('#new-middleware-form').prependTo('.enews-middleware-placeholder');
            $('#new-middleware-form h3').removeClass('open');
            $('#new-middleware-form .subscription-form').show();

            $('#donate-form-sidebar h3').removeClass('open');
            $('#enews-sidebar .ea_widget_container').show();
            // optimizely check - which one is supposed to be visible
            if ($('#donate-form-sidebar').css('display') != 'none') {
                $('#donate-form-sidebar form').show();
            }
        }
    });

    /* move donate sub-nav into mobile menu */
    if ( $( ".sidebar .section-nav ul" ).length ) {
        var sub_nav = $('.sidebar .section-nav ul');
        sub_nav = sub_nav[0]['innerHTML'];
        enquire.register("screen and (max-width: 740px)", {
            setup : function() { 
                $('.sidebar .section-nav ul').before('<span class="original-position"></span');
            },
            match : function() {
                if ( $('#main-nav ul li.nav-donate').hasClass('current-menu-ancestor') ) {
                    $('#main-nav ul li.current-menu-ancestor > a' ).after('<ul class="dropdown">'+sub_nav+'</ul>');
                }
            },
            unmatch : function() {
                $('#main-nav ul li.current-menu-ancestor ul.dropdown').detach();
            }
        });
    }

    /**
     * Append share text to social share buttons.
     */
    var shareText = '<p class="social-share-notice">' +
        'Sharing our content may mean you also share your personal data with the chosen social media platform. Find out more' +
        '<a href="/about/site/privacy#personal-data" target="_blank"> here</a>' +
        '.</p>';
    //$('.minimal-social').append(shareText);
}); /* end of as page load scripts */

/*
 * Load up Foundation
 */
(function(jQuery) {
  jQuery(document).foundation().foundation('interchange', {
      named_queries : {
        'peta-phone' : 'only screen and (max-width: 640px)',
        'peta-desktop' : 'only screen and (min-width: 641px)'
      }
    });
})(jQuery);


function loadFacebookShare( url, redirect, title, location_label ) {
    if (typeof(location_label)==='undefined') location_label = 'Share';
    var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
    var URL = "https://www.facebook.com/dialog/share?" +
    "app_id=523620961146074" +
    "&display=popup" +
    "&href=" + url +
    "&redirect_uri=" + redirect;
    return window.open(URL, "_blank", strWindowFeatures);
}

function loadTwitterShare( url, text, title, location_label ) {
    if (typeof(location_label)==='undefined') location_label = 'Share';
    var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
    var URL = "https://twitter.com/intent/tweet?" +
        "&text=" + text +
        "&url=" + url;
    return window.open(URL, "_blank", strWindowFeatures);
}

function socialToggle() {
    jQuery( '.social-links a.social').click( function(e) {

        var share_location = 'Share';
        var data_share_location = jQuery( this ).data( 'share-location' );
        if ( undefined !== data_share_location && data_share_location.length ) {
            share_location = data_share_location;
        }

        var shareMethod = jQuery( this ).data( 'social' );
        if ( shareMethod == 'facebook' ) {
            loadFacebookShare( jQuery( this ).data( 'url' ), jQuery( this ).data( 'redirect' ), jQuery( this ).attr( 'title' ), share_location );
        } else if ( shareMethod == 'twitter' ) {
            loadTwitterShare( jQuery( this ).data( 'url' ), jQuery( this ).data( 'text' ), jQuery( this ).attr( 'title' ), share_location );
        }
    });
}
(function( $ ){
	$('iframe').each(function() {
        var src = $(this).attr('src')?$(this).attr('src'):$(this).data('src'); 
		if (src.includes('vimeo') || src.includes('youtube') ) {
			$(this).wrap('<div class="responsive-embed widescreen"/>');
		}
	});
})( jQuery );